
import { Component, Vue } from 'vue-property-decorator';
import AlertErrorComponent from '../../components/alert-error/alert-error.component.vue';
import userService from '../Administrative/modules/Users/services/user.service';

@Component({ components: { AlertErrorComponent } })
export default class ResetPasswordComponent extends Vue {
  private email: string = '';

  private errors: object = {};

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private goToLogin() {
    this.$router.push({
      name: 'login',
    });
  }

  private recoverPassword(): void {
    userService
      .resetPassword(this.email)
      .then((data: any) => {
        this.errors = {};
        this.$snackbar.open({
          text: data.message,
          color: 'success',
          buttonColor: 'white',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }
}
